import { memo } from "react";

const textStyle = {
     fontFamily: "'Open Sans', sans-serif"
};

const spanStyle = {
     color: "#ff914d"
};

function PTRecommendsText(props: { bolded?: boolean, plainText?: boolean, shortForm?: boolean }) {

     const trailingText = props.shortForm
          ? ""
          : "ecommends";

     if (props.plainText) {
          return (<span>PTR{ trailingText }</span>);
     }

     const fontStyle = props.bolded === false
          ? ""
          : "font-semibold";

     return (<span className={ fontStyle } style={ textStyle }><em><span style={ spanStyle }>PT</span>R</em>{ trailingText }</span>);
}

export default memo(PTRecommendsText);
