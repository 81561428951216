import { lazy } from "react";
import { Link, Navigate, useSearchParams } from "react-router-dom";
import PageTitle from "../Components/PageTitle";
import GetConfiguration from "../Configuration";
import useData from "../Data";
import IHomePageCategoryCard from "../Models/IHomePageCategoryCard";
import IProduct from "../Models/IProduct";
import PTRecommendsText from "../Components/PTRecommendsText";
import Content from "../Components/Content";

const AlertError = lazy(() => import('../Components/AlertError'));


function HeaderAdSection() {
    return (<>
        <div className="txp-home-ad bg-white">
            <Content className={ `pt-16 sm:pt-28 lg:pt-24 pb-16 sm:pb-28 lg:pb-24` }>
                <div className="text-2xl sm:text-5xl text-center">
                    What's the next step to find the equipment you need?
                    Search <PTRecommendsText /> to purchase with <span className="txp-orange">confidence</span>.
                </div>
                <div className="mt-16 text-center sm:text-2xl">
                    Equipment used and recommended by healthcare professionals.
                </div>
            </Content>
        </div>
        <div className="bg-white pt-4 sm:pt-8 pb-8 sm:pb-12 shadow-lg">
            <Content>
                <div className="flex flex-col sm:flex-row px-6 sm:px-0">
                    <div className="mb-4 sm:w-1/3">
                        <div className="sm:p-4 sm:h-full flex sm:flex-col justify-between items-center">
                            <div className="pr-2 sm:pr-0 sm:pb-4 w-full font-bold text-center sm:h-1/3">
                                Type product need into the search bar
                            </div>
                            <img src="/magnify-1.svg" className="w-20 sm:w-36" alt="" />
                        </div>
                    </div>
                    <div className="mb-4 sm:w-1/3">
                        <div className="sm:p-4 h-full flex sm:flex-col justify-between items-center">
                            <div className="pr-2 sm:pr-0 sm:pb-4 w-full font-bold text-center sm:h-1/3">
                                Benefit from expert reviews
                            </div>
                            <img src="/cap-2.svg" className="w-20 sm:w-36" alt="" />
                        </div>
                    </div>
                    <div className="sm:w-1/3">
                        <div className="sm:p-4 h-full flex sm:flex-col justify-between items-center">
                            <div className="pr-2 sm:pr-0 sm:pb-4 w-full font-bold text-center sm:h-1/3">
                                Click the link to purchase from your preferred vendor
                            </div>
                            <img src="/click-3.svg" className="w-20 sm:w-36" alt="" />
                        </div>
                    </div>
                </div>
            </Content>
        </div>
    </>);
}

function FriendlyProductLink(product: IProduct) {
    const name = product.name?.replaceAll(" ", "-") || "-";
    return `/pr/${ encodeURIComponent(name) }/${ product.id }`;
}

function CategoryCard(props: { categoryCard: IHomePageCategoryCard }) {
    const { categoryCard } = props;

    const category = categoryCard.category;
    const products = categoryCard.products || [];

    const configuration = GetConfiguration();

    return (<div className="px-2 pb-2 block rounded border h-full bg-white">
        <Link to={ `/ct/${ category?.name }/${ category?.id }` }>
            <h2 className="my-1 pl-1 text-xl pb-px cursor-pointer hover:pb-0 hover:border-b hover:border-orange-500">
                { category?.name }
            </h2>
        </Link>
        <div className="flex flex-wrap">
            { products.map(product =>
                <div className="p-1 w-6/12 flex justify-center" key={ product?.id }>
                    <Link to={ FriendlyProductLink(product) } className="pb-px cursor-pointer hover:pb-0 hover:border-b hover:border-orange-500">
                        <div className="flex flex-col justify-center max-h-full p-1">
                            <img className="object-contain max-h-full m-2"
                                src={ `${ configuration.MediaUrl }view/${ product?.imageUrl }?h=75` } alt="" style={ { "height": 75 } } />
                            <span className="text-xs">{ product.name }</span>
                        </div>
                    </Link>
                </div>
                ) }
        </div>
    </div>);
}

function HomePage() {
    const data = useData();

    const [ searchParams ] = useSearchParams();

    const searchValue = searchParams.get("q")?.trim() || searchParams.get("search")?.trim() || "";

    const { isLoading, isError, data: categoryCards, error } = data.GetHomePageCategoryCards();

    if (searchValue) {
        return (<Navigate to={ `/search?q=${ encodeURIComponent(searchValue) }` } replace={ true } />);
    }

    if (isError) {
        return (<div>
            <AlertError error={ error as Error } />
        </div>);
    }

    if (isLoading || !categoryCards) {
        return (<div>
            {/* <LoadingIndicator /> */}
        </div>)
    }

    return (<>
        <PageTitle title="" />
        <div className="bg-gray-100">

            <div className="pt-12 sm:pt-20 pb-4">
                <HeaderAdSection />
            </div>

            <Content className="pt-4 pb-10">
                <div className="flex flex-wrap">
                    { categoryCards.map(categoryCard =>
                        <div className="p-1 w-full sm:w-6/12 md:w-4/12 xl:w-3/12" key={ categoryCard.category?.id }>
                            <CategoryCard categoryCard={ categoryCard } />
                        </div>
                        ) }
                </div>
            </Content>
        </div>
    </>);
}

export default HomePage;
