import { PropsWithChildren } from "react";
import { Link } from "react-router-dom";
import Navbar from "../Components/Navbar";
import PTRecommendsText from "../Components/PTRecommendsText";

function Layout(props: PropsWithChildren<{}>) {
    return (<>
        <Navbar />

        { props.children }

        <footer className="mb-5 pt-3 flex justify-center text-xs border-t border-gray-300">
            <div className="flex flex-wrap mx-10 lg:max-w-5xl">
                <div className="w-full md:w-8/12 md:text-center">
                    <PTRecommendsText plainText={ true } /> is a free online magazine run by
                    physical therapists that care about efficiently sharing
                    quality therapy equipment with therapy professionals and patients
                </div>
                <div className="w-full mt-2 text-center text-blue-600 md:mt-0 md:pl-3 md:w-4/12 md:text-start">
                    <Link to="/affiliate-disclosure" className="hover:underline mr-3 md:block md:mb-1">Affiliate Disclosure</Link>
                    <Link to="/privacy-policy" className="hover:underline md:block">Privacy Policy</Link>
                </div>
                <div className="w-full text-center mt-2">
                    Copyright &copy; <PTRecommendsText plainText={ true } /> 2022. All rights reserved.
                </div>
            </div>
        </footer>
    </>);
}

export default Layout;
