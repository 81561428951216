interface IConfiguration {
    MediaUrl: string;
    PTRecommendsUrl: string;
}

function GetConfiguration(): IConfiguration {
    const hostname = window.location.hostname;
    if (hostname.includes("localhost") || hostname.includes("devptrecommends") || hostname.includes("ptrecommends-dev")) {
        return {
            MediaUrl: "https://theraxpro-media-dev.azurewebsites.net/",
            PTRecommendsUrl: "https://devptrecommends.azurewebsites.net/"
        };
    } else {
        return {
            MediaUrl: "https://media.theraxpro.com/",
            PTRecommendsUrl: "https://ptrecommends.com/"
        };
    }
}

export default GetConfiguration;
