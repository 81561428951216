import { useInfiniteQuery, UseInfiniteQueryResult, useQueries, useQuery, useQueryClient, UseQueryResult } from "react-query";
import useApi from "./Api";
import ICategory from "./Models/ICategory";
import ICategoryProductPage from "./Models/ICategoryProductPage";
import IHomePageCategoryCard from "./Models/IHomePageCategoryCard";
import IProduct from "./Models/IProduct";
import IVendor from "./Models/IVendor";


interface IData {
    GetCategories: () => UseQueryResult<ICategory[], unknown>;
    GetCategoryById: (categoryId?: string) => UseQueryResult<ICategory, unknown>;
    GetHomePageCategoryCards: () => UseQueryResult<IHomePageCategoryCard[], unknown>;
    GetProductById: (productId?: string) => UseQueryResult<IProduct, unknown>;
    GetProductsByIds: (productIds?: string[]) => UseQueryResult<IProduct, unknown>[];
    GetProductsByCategoryId: (categoryId?: string, offset?: number, count?: number, options?: { enabled?: boolean }) => UseInfiniteQueryResult<ICategoryProductPage, unknown>;
    GetVendors: () => UseQueryResult<IVendor[], unknown>;
    Search: (query?: string) => UseQueryResult<IProduct[], unknown>;
}


function useData(): IData {
    const api = useApi();
    const queryClient = useQueryClient();

    return {
        GetCategories() {
            return useQuery("categories", () => api.GetCategories(), {
                onSuccess(categories) {
                    for (const category of categories) {
                        queryClient.setQueryData([ "category", category.id ], category);
                    }
                },
            });
        },

        GetCategoryById(categoryId) {
            return useQuery([ "category", categoryId ], () => api.GetCategoryById(categoryId), {
                enabled: !!categoryId
            });
        },

        GetHomePageCategoryCards() {
            return useQuery("homepage-category-cards", () => api.GetHomePageCategorycards(), {
                onSuccess(cards) {
                    for (const card of cards) {
                        const category = card.category;
                        if (category) {
                            queryClient.setQueryData([ "category", category.id ], category);
                        }
                        const products = card.products;
                        if (products) {
                            for (const product of products) {
                                queryClient.setQueryData([ "product", product.id ], product);
                            }
                        }
                    }
                },
            });
        },

        GetProductById(productId) {
            return useQuery([ "product", productId ], () => api.GetProductById(productId), {
                enabled: !!productId
            });
        },

        GetProductsByIds(productIds) {

            const queries = (productIds || []).map(productId => {
                return {
                    queryKey: [ "product", productId ],
                    queryFn: () => api.GetProductById(productId),
                    enabled: !!productId
                    }
                });

            return useQueries(queries);
        },

        GetProductsByCategoryId(categoryId, offset, count, options) {

            const apiCall = async (pageParam: number) => {
                const page = await api.GetProductsByCategoryId(categoryId, pageParam, count);
                if (page.products) {
                    for (const product of page.products) {
                        queryClient.setQueryData([ "product", product.id ], product);
                    }
                }
                return page;
            };

            return useInfiniteQuery([ "category-products", categoryId],
                ({ pageParam = offset }) => apiCall(pageParam),
                {
                    enabled: !!categoryId && options?.enabled,
                    getNextPageParam: (lastPage, _) => lastPage.nextCursor,
                });
        },

        GetVendors() {
            return useQuery("vendors", () => api.GetVendors());
        },

        Search(query) {
            query = query?.trim();
            return useQuery(["search", query], () => api.Search(query), {
                enabled: !!query,
                onSuccess(products) {
                    for (const product of products) {
                        queryClient.setQueryData([ "product", product.id ], product);
                    }
                },
            });
        },
    };
}

export default useData;
