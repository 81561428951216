import { Helmet } from "react-helmet-async";

function PageTitle(props: { title?: string }) {
    return (<Helmet>
        <title>{ props.title
            ? `PTRecommends.com: ${ props.title }`
            : "PTRecommends.com"
            }</title>
    </Helmet>);
}

export default PageTitle;
