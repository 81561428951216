import { Link, NavLink, useLocation, useSearchParams } from "react-router-dom";
import PTRecommendsText from "./PTRecommendsText";
import SearchBox from "./SearchBox";
import AlertError from "./AlertError";
//import LoadingIndicator from "./LoadingIndicator";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useData from "../Data";


function MainNavStyle(props: { isActive: boolean }) {
    return `hidden sm:block mx-3 text-sm uppercase ${ props.isActive ? "underline underline-offset-8 decoration-[#ff914d]" : "text-gray-400 hover:text-white" }`;
}

function CategoryNavStyle(props: { isActive: boolean }) {
    return props.isActive ? "underline underline-offset-4 decoration-[#ff914d]" : "hover:underline hover:underline-offset-4 hover:decoration-[#ff914d]";
}

function Navbar() {
    const data = useData();
    const location = useLocation();
    const navigate = useNavigate();
    const [ searchParams ] = useSearchParams();

    const [ navIsOpen, setIsNavOpen ] = useState(false);

    function ToggleMenu() {
        setIsNavOpen(state => !state);
    }

    function CloseMenu() {
        setIsNavOpen(false);
    }

    useEffect(() => {
        CloseMenu();
    }, [ location ]);

    const { isLoading, isError, data: categories, error } = data.GetCategories();

    const searchOnChange = useCallback(async (searchText: string) => {
        const value = searchText.trim();
        if (value) {
            navigate(`/search?q=${ value }`);
        }
    }, [ navigate ]);


    if (isError) {
        return (<div>
            <AlertError error={ error as Error } />
        </div>);
    }

    if (isLoading || !categories) {
        return (<div>
            {/* <LoadingIndicator /> */}
        </div>)
    }

    return (<nav className="fixed w-full z-50">
        <div className="flex items-center px-2 py-2 bg-gray-800 text-white">
            <span className="block sm:hidden mr-3 cursor-pointer" onClick={ () => ToggleMenu()}>
                <svg className={`block h-8 w-8`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="white" aria-hidden="true">
                    { !navIsOpen &&
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                        }
                    { navIsOpen &&
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        }
                </svg>
            </span>
            <span className="text-xl tracking-tight">
                <span className="hidden sm:inline">
                    <Link to="/">
                        <PTRecommendsText />
                    </Link>
                </span>
                <span className="inline sm:hidden">
                    <Link to="/">
                        <PTRecommendsText shortForm={ true } />
                    </Link>
                </span>
            </span>
            <div className="w-full flex flex-grow items-center">

                <div className="flex-grow ml-5 mr-0 sm:mr-3 flex justify-center">
                    <SearchBox maxLength={ 50 } value={ searchParams.get("q")?.trim() || "" } onChange={ searchOnChange } className="w-full" />
                </div>

                <NavLink to="/blog" className={ MainNavStyle }>Blog</NavLink>
                <NavLink to="/about" className={ MainNavStyle }>About</NavLink>
            </div>
        </div>
        <div className="sm:hidden w-2/3 rounded-b bg-gray-700 drop-shadow-lg z-50">
            { navIsOpen &&
                <div className="px-2 py-2 text-md text-white max-h-full">
                    { categories.map(category =>
                        <div className="px-2 py-1 hover:underline hover:underline-offset-4 hover:decoration-[#ff914d]" key={ category.id }>
                            <NavLink to={ `/ct/${ category.name }/${ category.id }` } className={ CategoryNavStyle }>
                                { category.name }
                            </NavLink>
                        </div>
                        ) }
                    <div className="mt-5 px-2 py-1">
                        <NavLink to="/blog" className={ CategoryNavStyle }>Blog</NavLink>
                    </div>
                    <div className="px-2 py-1">
                    <NavLink to="/about" className={ CategoryNavStyle }>About</NavLink>
                    </div>
                </div>
                }
        </div>
        <div className="hidden sm:flex flex-wrap justify-center space-x-3 px-2 pt-1 pb-1.5 bg-gray-700 text-white shadow-lg">
            { categories.map(category =>
                <div key={ category.id }>
                    <span className="text-sm hover:underline hover:underline-offset-4 hover:decoration-[#ff914d]">
                        <NavLink to={ `/ct/${ category.name }/${ category.id }` } className={ CategoryNavStyle }>
                            { category.name }
                        </NavLink>
                    </span>
                </div>
                ) }
        </div>
    </nav>);
}

export default Navbar;
