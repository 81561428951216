import ICategory from "./Models/ICategory";
import ICategoryProductPage from "./Models/ICategoryProductPage";
import IHomePageCategoryCard from "./Models/IHomePageCategoryCard";
import IProduct from "./Models/IProduct";
import IVendor from "./Models/IVendor";

interface IApi {
    GetCategories: () => Promise<ICategory[]>;
    GetCategoryById: (categoryId?: string) => Promise<ICategory>;
    //GetCategoryProductIds: (categoryId?: string, offset?: number, count?: number) => Promise<ICategoryProductPage>;
    GetHomePageCategorycards: () => Promise<IHomePageCategoryCard[]>;
    GetProductById: (productId?: string) => Promise<IProduct>;
    GetProductsByCategoryId: (categoryId?: string, offset?: number, count?: number) => Promise<ICategoryProductPage>;
    GetVendors: () => Promise<IVendor[]>;
    Search: (search?: string) => Promise<IProduct[]>;
}


// simulate delay

//function rng(min: number, max: number) {
//    return Math.floor(min + (Math.random() * max));
//}

//function sleep(ms: number) {
//    return new Promise(resolve => setTimeout(resolve, ms));
//}

class Api {

    root = "/api";

    httpCall = async<TResult>(resource: string, options: any) => {

        //await sleep(rng(2000, 5000));

        const url = `${ this.root }/${ resource }`;
        const response = await fetch(url, options);
        if (!response.ok) {
            throw new Error(`Error fetching ${url}`);
        }

        const json = await response.json();
        return json as TResult;
    };

    httpGet = async<TResult>(resource: string) => {
        return await this.httpCall<TResult>(resource, {
            method: "GET"
        });
    };

    httpPost = async<TResult>(resource: string, body: any) => {
        return await this.httpCall<TResult>(resource, {
            headers: { 'Content-Type': 'application/json' },
            method: "POST",
            body: JSON.stringify(body)
        });
    };

    compare(lhs?: string, rhs?: string) {
        const lhsn = lhs || "";
        const rhsn = rhs || "";
        if (lhsn < rhsn) {
            return -1;
        } else if (lhsn > rhsn) {
            return 1;
        } else {
            return 0;
        }
    }

    GetVendors = async () => {
        const vendors = await this.httpGet<IVendor[]>("vendors");
        vendors.sort((lhs, rhs) => this.compare(lhs.name, rhs.name));
        return vendors;
    };

    GetCategories = async () => {
        const categories = await this.httpGet<ICategory[]>("categories");
        categories.sort((lhs, rhs) => this.compare(lhs.name, rhs.name));
        return categories;
    };

    GetCategoryById = async (categoryId?: string) => {
        const category = await this.httpGet<ICategory>(`category/${ categoryId }`);
        return category;
    };

    // GetCategoryProductIds = async (categoryId?: string, offset?: number, count?: number) => {
    //     return await this.httpGet<ICategoryProductPage>(`category/${ categoryId }/products/${ offset }/${ count }`);
    // };

    GetHomePageCategorycards = async () => {
        return await this.httpGet<IHomePageCategoryCard[]>("homepage/cards");
    };

    GetProductById = async (productId?: string) => {
        return await this.httpGet<IProduct>(`product/${ productId }`);
    };

    GetProductsByCategoryId = async (categoryId?: string, offset?: number, count?: number) => {
        return await this.httpGet<ICategoryProductPage>(`category/${ categoryId }/products/${ offset }/${ count }?includeProducts=true`);
    };

    Search = async (search?: string) => {
        search = (search || "").trim();
        if (!search) {
            return Promise.resolve([]);
        }
        return await this.httpPost<IProduct[]>("product/search", { search });
    };

}


const api = new Api();

function useApi(): IApi {
    return api;
}

export default useApi;
