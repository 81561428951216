import { useEffect, useState } from "react";

function SearchBox(props: { className?: string, maxLength?: number, value?: string, onChange?: (search: string) => void }) {

    const [ search, setSearch ] = useState(props.value || "");

    useEffect(() => {
        setSearch(props.value || "");
    }, [props]);

    function onChange(value: string) {
        setSearch(value);
    }

    function onKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key !== "Enter") {
            return;
        }

        const handler = props.onChange;
        if (!handler) {
            return;
        }

        handler(search.trim());

        const target: any = e.target;
        target.blur();
    }

    return (<div className={ `relative text-gray-600 ${ props.className || "" }` }>
        <span className="absolute inset-y-0 left-0 flex items-center pl-2">
            <span className="p-1 focus:outline-none focus:shadow-outline">
                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" className="w-6 h-6">
                    <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                </svg>
            </span>
        </span>
        <input type="input" placeholder="search" autoComplete="off" maxLength={ props.maxLength }
            value={ search }
            onFocus={ e => e.target.select() }
            onChange={ e => onChange(e.target.value) }
            onKeyDown={ e => onKeyDown(e) }
            className={ `txp-search-input py-2 pl-10 w-full text-sm rounded-md focus:outline-none` }
            />
    </div>);
}

export default SearchBox;
