import { memo } from "react";

function AlertError(props: { error: Error }) {
    return (<div role="alert" className="rounded border border-red-400 bg-red-100 text-red-700 mt-3 flex">
        <span className="bg-red-500 text-white font-bold px-3 align-middle inline-flex items-center">
            Error
        </span>
        <span className="px-2 text-xs break-all">{ props.error.message }</span>
    </div>);
}

export default memo(AlertError);
