import { BrowserRouter, Route, Routes, useLocation, useNavigationType } from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';
import { lazy, Suspense, useEffect } from "react";
import { QueryClient, QueryClientProvider } from 'react-query';
import HomePage from "./Pages/HomePage";
import Layout from "./Layouts/Layout";
import PageTitle from './Components/PageTitle';
import ReactGA from 'react-ga4';
import "./App.scss"

const AboutPage = lazy(() => import("./Pages/AboutPage"));
const AffiliateDisclosurePage = lazy(() => import("./Pages/AffiliateDisclosurePage"));
const BlogPage = lazy(() => import("./Pages/BlogPage"));
const CategoryPage = lazy(() => import("./Pages/CategoryPage"));
const PageNotFoundPage = lazy(() => import("./Pages/PageNotFoundPage"));
const PrivacyPolicyPage = lazy(() => import("./Pages/PrivacyPolicyPage"));
const SearchPage = lazy(() => import("./Pages/SearchPage"));
const ViewProductPage = lazy(() => import("./Pages/ViewProductPage"));


function GoogleAnalytics() {
    const location = useLocation();

    useEffect(() => {
        ReactGA.send("pageview");
        //const fullPath = location.pathname + location.search + location.hash;
        //ReactGA.set({ page: fullPath });
        //ReactGA.pageview(fullPath);
    }, [location]);

    return <></>;
}

function ScrollToTop() {
    const navigationType = useNavigationType();
    const { pathname } = useLocation();

    useEffect(() => {
        if (navigationType === "PUSH") {
            window.scrollTo(0, 0);
        }
    }, [pathname, navigationType]);

    return <></>;
}


function App() {
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
                staleTime: 60_000
                }
            }
        });

    return (<QueryClientProvider client={queryClient}>
        <HelmetProvider>
            <BrowserRouter>
                <GoogleAnalytics />
                <ScrollToTop />
                <PageTitle />
                <Layout>
                    <Suspense>
                        <Routes>
                            <Route path="/" element={ <HomePage /> } />
                            <Route path="/about" element={ <AboutPage /> } />
                            <Route path="/affiliate-disclosure" element={ <AffiliateDisclosurePage /> } />
                            <Route path="/ct/:name/:categoryId" element={ <CategoryPage />} />
                            <Route path="/blog" element={ <BlogPage /> } />
                            <Route path="/blog/:name" element={ <BlogPage /> } />
                            <Route path="/privacy-policy" element={ <PrivacyPolicyPage /> } />
                            <Route path="/pr/:productId" element={ <ViewProductPage /> } />
                            <Route path="/pr/:name/:productId" element={ <ViewProductPage /> } />
                            <Route path="/product/:productId" element={ <ViewProductPage /> } />
                            <Route path="/product/:name/:productId" element={ <ViewProductPage /> } />
                            <Route path="/search" element={ <SearchPage /> } />
                            <Route path="*" element={ <PageNotFoundPage /> } />
                        </Routes>
                    </Suspense>
                </Layout>
            </BrowserRouter>
        </HelmetProvider>
    </QueryClientProvider>);
}

export default App;
